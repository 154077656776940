/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useMemo, useState } from "react";
import { mediaQueries } from "../../mediaQueries";
import {
	BLUE,
	MID_GRAY,
	MUTED_BLUE,
	MUTED_RED,
	RED,
	VIBRANT_RED,
} from "../../colors";
import { DateTime } from "luxon";
import SectionHeader from "../SectionHeader";

export default function InterestForm() {
	const [campaignDescription, setCampaignDescription] = useState("");
	const [nameOfInquirer, setNameOfInquirer] = useState("");
	const [positionOfInquirer, setPositionOfInquirer] = useState("");
	const [contactInfo, setContactInfo] = useState("");

	const [inquirySubmission, setInquirySubmission] = useState<
		"success" | "error" | null
	>(null);

	const readyToSubmit = useMemo(
		() =>
			campaignDescription.length &&
			nameOfInquirer.length &&
			positionOfInquirer.length &&
			contactInfo.length,
		[campaignDescription, nameOfInquirer, positionOfInquirer, contactInfo],
	);

	const funnyMoneyPics = [
		"https://imgs.search.brave.com/jYbUAmmOBv1OYuczLvJjm5pWCr7BBj0XrJ7upiPbvyk/rs:fit:1200:720:1/g:ce/aHR0cHM6Ly9pbWFn/ZS50bWRiLm9yZy90/L3Avb3JpZ2luYWwv/ZEt6bTVyYnVUeEJP/MlBPdnpEQ2habzVy/c2piLmpwZw",
		"https://imgs.search.brave.com/Q6pBolIL9YP3asmCUg3vPLJpBEw0nhPP5jEstGYFGCk/rs:fit:888:1200:1/g:ce/aHR0cDovL2NkbjAx/LmNkbi5qdXN0amFy/ZWQuY29tL3dwLWNv/bnRlbnQvdXBsb2Fk/cy8yMDA3LzExL21h/ZC1tb25leS9tYWQt/bW9uZXktbW92aWUt/cG9zdGVyLTAxLmpw/Zw",
		"https://image.tmdb.org/t/p/original/3Y3wNbBozb84vB1djVNw1jVYJv0.jpg",
		"https://cdn.0123movies.com/upload/2017/10/movie_wheres-the-money-2017.jpg",
		"https://i.pinimg.com/originals/e2/2d/b6/e22db6b4ab7a799f1baeed78196a3414.jpg",
		"https://imgs.search.brave.com/NjFeWuudZYhnDqFchNca6QZy-8Oolxg3Jq2DyZ1c3jo/rs:fit:721:720:1/g:ce/aHR0cHM6Ly9pMC53/cC5jb20vd3d3LnNh/dmluZ3NhbmRzYW5n/cmlhLmNvbS93cC1j/b250ZW50L3VwbG9h/ZHMvMjAxOC8wOC9G/ZWF0dXJlLTc5LW1v/bmV5LWxlc3NvbnMt/ZnJvbS1kaXNuZXkt/bW92aWVzLmpwZz9m/aXQ9NzIxJTJDNzIw/JnNzbD0x",
		"https://prod-ripcut-delivery.disney-plus.net/v1/variant/disney/02F2C27D2535B829D85D9DD8FBCDA23A2BD7805A136AD060DBFCC315814FC4D3/scale?width=1200&aspectRatio=1.78&format=jpeg",
	];

	const invokeSlackWebhook = () => {
		return fetch(
			"https://hooks.slack.com/services/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",
			{
				method: "POST",
				// Strangely unsupport by Slack.
				// headers: { "content-type": "application/json" },
				// mode: "cors",
				body: JSON.stringify({
					text: "Test Message 2",
					blocks: [
						{
							type: "image",
							image_url:
								"https://oouphgurrsruwvayrzte.supabase.co/storage/v1/object/public/marketing/future-campaign/slack-flag",
							alt_text: "inspiration",
						},
						{
							type: "header",
							text: {
								type: "plain_text",
								text: "Future Campaign Inquiry",
								emoji: true,
							},
						},
						{
							type: "context",
							elements: [
								{
									type: "plain_text",
									text: DateTime.now().toLocaleString(DateTime.DATETIME_FULL),
									emoji: true,
								},
							],
						},
						{
							type: "divider",
						},
						{
							type: "section",
							fields: [
								{
									type: "mrkdwn",
									text: `*Who:*\n${nameOfInquirer}`,
								},
								{
									type: "mrkdwn",
									text: `*Position:*\n${positionOfInquirer}`,
								},
								{
									type: "mrkdwn",
									text: `*Contact:*\n${contactInfo}`,
								},
							],
						},
						{
							type: "section",
							text: {
								type: "mrkdwn",
								text: campaignDescription,
							},
						},
					],
				}),
			},
		);
	};

	const formFieldCSS = css({
		fontFamily: "Inter",
		fontSize: 14,
		fontWeight: 500,
		borderRadius: 5,
		outline: "none",
		border: "none",
		padding: 15,
		color: MUTED_RED,
		backgroundColor: "white",
		transition: "all .5s ease",
		[mediaQueries[3]]: {
			fontSize: 16,
		},
		boxSizing: "border-box",
		"::placeholder": {
			color: MID_GRAY,
			textTransform: "uppercase",
		},
		":focus": {
			color: "white",
			backgroundColor: MID_GRAY,
			"::placeholder": {
				color: "white",
			},
		},
	});

	return (
		<div
			css={{
				maxWidth: "100%",
				boxSizing: "border-box",
				[mediaQueries[1]]: {
					maxWidth: "70%",
				},
				[mediaQueries[2]]: {
					maxWidth: "50%",
				},
			}}
		>
			{inquirySubmission === null ? (
				<>
					<textarea
						id="project-description"
						placeholder="TELL us the basics about your campaign"
						value={campaignDescription}
						onChange={(event) => setCampaignDescription(event.target.value)}
						css={[
							formFieldCSS,
							{
								width: "100%",
								resize: "none",
								marginBottom: 10,
							},
						]}
						rows={8}
					/>
					<div
						css={{
							display: "grid",
							gridTemplateColumns: "1fr",
							gap: 15,
							[mediaQueries[1]]: {
								gridTemplateColumns: "3fr 1fr",
								gap: 25,
							},
						}}
					>
						<div css={{ display: "grid", gap: 10 }}>
							<div
								css={{
									display: "grid",
									gridTemplateColumns: "1fr 1fr",
									gap: 10,
								}}
							>
								<input
									placeholder="NAME"
									value={nameOfInquirer}
									onChange={(event) => setNameOfInquirer(event.target.value)}
									css={[formFieldCSS, { width: "100%" }]}
								/>
								<input
									placeholder="POSITION"
									value={positionOfInquirer}
									onChange={(event) =>
										setPositionOfInquirer(event.target.value)
									}
									css={[formFieldCSS, { width: "100%" }]}
								/>
							</div>
							<input
								placeholder="EMAIL ADDRESS OR PHONE NUMBER"
								value={contactInfo}
								onChange={(event) => setContactInfo(event.target.value)}
								css={[formFieldCSS]}
							/>
						</div>
						<button
							css={[
								formFieldCSS,
								{
									fontWeight: readyToSubmit ? 800 : 600,
									backgroundColor: readyToSubmit ? MUTED_BLUE : MID_GRAY,
									color: "white",
									order: 2,
									cursor: "pointer",
									opacity: readyToSubmit ? 1 : 0.4,
									pointerEvents: readyToSubmit ? "all" : "none",
									":focus, :hover": {
										color: "white",
										backgroundColor: VIBRANT_RED,
										"::placeholder": {
											color: "white",
										},
									},
								},
							]}
							type="submit"
							onClick={() => {
								invokeSlackWebhook()
									.then((response) => setInquirySubmission("success"))
									.catch((error) => setInquirySubmission("error"));
							}}
						>
							<p css={{ margin: 0, fontSize: 12 }}>I'M READY</p>
							<p css={{ margin: 0, fontSize: 16 }}>TO WIN</p>
						</button>
					</div>
				</>
			) : null}

			{inquirySubmission === "success" ? (
				<div css={{ backgroundColor: "white", padding: 20, borderRadius: 5 }}>
					<SectionHeader
						text="We are grateful for the opportunity to serve you."
						color={MUTED_BLUE}
						additionalCSS={css({
							marginTop: 0,
							[mediaQueries[0]]: {
								transform: "scale(.6)",
							},
							transformOrigin: "left",
							transition: "all .5s ease",
						})}
					/>

					<p
						css={{
							fontFamily: "Inter",
							color: MID_GRAY,
							fontSize: 14,
							fontWeight: 300,
							margin: 0,
							marginTop: 15,
							[mediaQueries[1]]: {},
							[mediaQueries[3]]: { fontSize: 18 },
						}}
					>
						We'll be in touch shortly. Be on the lookout for a message from our
						team. <br />
						We can't wait to learn more about you and your project.
					</p>
				</div>
			) : inquirySubmission === "error" ? (
				<div css={{ backgroundColor: "white", padding: 20, borderRadius: 5 }}>
					<SectionHeader
						text="It looks like the internet is having a hiccup."
						color={RED}
						additionalCSS={css({
							marginTop: 0,
							[mediaQueries[0]]: {
								transform: "scale(.6)",
							},
							transformOrigin: "left",
							transition: "all .5s ease",
						})}
					/>
					<p
						css={{
							fontFamily: "Inter",
							color: MID_GRAY,
							fontSize: 14,
							fontWeight: 300,
							margin: 0,
							marginTop: 15,
							[mediaQueries[1]]: {},
							[mediaQueries[3]]: { fontSize: 18 },
						}}
					>
						Your submission was great, but couldn't be delivered because of a
						problem with our messaging provider.
					</p>
					<p
						css={{
							fontFamily: "Inter",
							color: MID_GRAY,
							fontSize: 14,
							fontWeight: 300,
							margin: 0,
							marginTop: 15,
							[mediaQueries[1]]: {},
							[mediaQueries[3]]: { fontSize: 18 },
						}}
					>
						You can reload the page and try again, or send us a quick email at{" "}
						<a
							css={{
								color: RED,
								fontWeight: 700,
							}}
							href="mailto:team@formfilms.com"
						>
							team@formfilms.com
						</a>
					</p>
				</div>
			) : null}
		</div>
	);
}

/** @jsxImportSource @emotion/react */

import welcomeBackground from "../assets/images/top-section-background.jpg";
import futureCampaignLogo from "../assets/images/Future Campaign - Logo.png";
import divider from "../assets/images/divider-1.png";

import { mediaQueries } from "../mediaQueries";
import { BLUE, RED } from "../colors";

export default function Welcome() {
	return (
		<section
			id="welcome"
			css={{
				position: "relative",
				backgroundImage: `url(${welcomeBackground})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				padding: 35,
				[mediaQueries[1]]: {
					padding: 50,
				},
			}}
		>
			<div
				css={
					{
						// backdropFilter: "brightness(65%) blur(5px)",
						// padding: 15,
						// borderRadius: 10,
					}
				}
			>
				<img
					src={futureCampaignLogo}
					alt="Future Campaign Logo"
					css={{ width: 150, [mediaQueries[4]]: { width: 200 } }}
				/>
			</div>
			<div
				id="welcome-text"
				css={{
					fontFamily: "Inter",
					color: "white",
					maxWidth: "75%",
					// ["@media (orientation: portrait)"]: {
					//   position: "absolute",
					// },

					marginTop: 100,
					marginBottom: 50,
					[mediaQueries[1]]: { marginTop: 200 },
					[mediaQueries[2]]: { marginTop: 250 },
					[mediaQueries[3]]: { marginTop: 300, marginBottom: 250 },
				}}
			>
				<h2
					css={{
						fontSize: 20,
						fontStyle: "italic",
						fontWeight: 400,
						margin: 0,

						[mediaQueries[1]]: {
							fontSize: 30,
							lineHeight: 1,
						},
						[mediaQueries[2]]: {},
						[mediaQueries[3]]: {},
						[mediaQueries[4]]: {
							fontSize: 40,
						},
					}}
				>
					TECHNOLOGY FOR CAMPAIGNS THAT
				</h2>
				<h1
					css={{
						fontSize: 75,
						lineHeight: 0.8,
						fontStyle: "italic",
						fontWeight: 900,
						margin: 0,
						// marginTop: -25,
						marginBottom: 35,
						marginLeft: -15,
						// [mediaQueries[0]]: {
						//   fontSize: 110,
						// },
						[mediaQueries[1]]: {
							fontSize: 120,
						},
						[mediaQueries[2]]: {},
						[mediaQueries[3]]: {},
						[mediaQueries[4]]: { fontSize: 140 },
					}}
				>
					<span css={{ color: RED }}>WANT</span> TO{" "}
					<span css={{ color: BLUE }}>WIN</span>
				</h1>
				<p
					css={{
						textTransform: "uppercase",
						fontWeight: 400,
						fontSize: 12,
						[mediaQueries[0]]: {
							fontSize: 14,
						},
						[mediaQueries[1]]: {
							fontSize: 16,
							maxWidth: 500,
						},
						[mediaQueries[2]]: {},
						[mediaQueries[3]]: {},
						[mediaQueries[4]]: {
							fontSize: 18,
							maxWidth: 550,
						},
					}}
				>
					Future Campaign is the next-gen messaging platform for CAMPAIGNS,
					allowing you to have a direct conversation with potential voters, at
					scale.
				</p>
			</div>

			<img
				src={divider}
				css={{
					maxWidth: "100%",
					position: "absolute",
					bottom: 0,
					left: 0,
				}}
				alt="section-divider"
			/>
		</section>
	);
}

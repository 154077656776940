/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { MID_GRAY } from "../colors";
import { mediaQueries } from "../mediaQueries";

import divider from "../assets/images/divider-3.png";
import divider4 from "../assets/images/divider-4.png";
import pieChart from "../assets/images/party-affiliation-bar-chart.png";
import geoChart from "../assets/images/us-geography.png";
import barChart from "../assets/images/engagement-chart.png";
import SectionHeader from "./SectionHeader";
import StyledParagraph from "./StyledParagraph";
import FeatureHighlight from "./FeatureHighlight";

export default function Analytics() {
	return (
		<section
			id="traditional-trailers-part-1-centering-div"
			css={{
				position: "relative",
				width: "100%",
				boxSizing: "border-box",
			}}
		>
			<img
				src={divider}
				css={{
					maxWidth: "100%",
					position: "relative",
					top: -1,
					left: 0,
				}}
				alt="section-divider"
			/>
			<div
				id="analytics-content"
				css={{
					padding: 35,
					paddingBottom: 100,
					[mediaQueries[1]]: {
						padding: 75,
						paddingBottom: 200,
					},
					[mediaQueries[4]]: {
						paddingBottom: 300,
					},
				}}
			>
				<SectionHeader
					text="UNPARALLELED ANALYTICS at a fraction of the cost"
					color={MID_GRAY}
					additionalCSS={css({
						[mediaQueries[0]]: {},
						[mediaQueries[1]]: {
							maxWidth: "50%",
						},
						[mediaQueries[2]]: {},
						[mediaQueries[3]]: {},
						[mediaQueries[4]]: {},
					})}
				/>
				<div
					css={{
						display: "grid",
						gridAutoRows: "auto",
						gridTemplateColumns: "1fr",
						gap: 50,
						marginTop: 35,
						flex: 1,
						flexDirection: "column",
						position: "relative",
						width: "100%",
						fontFamily: "Inter",

						[mediaQueries[1]]: {
							gridTemplateColumns: "1fr 1fr",
						},
					}}
				>
					<div id="grid-section-1">
						<StyledParagraph color={MID_GRAY}>
							Polling and focus groups can be time-consuming, expensive, and
							often yield skewed or limited data. This is especially true as a
							growing number of voters may feel hesitant to share their beliefs
							amidst rising tensions in America's political discourse.
						</StyledParagraph>
						<StyledParagraph color={MID_GRAY}>
							Now, with our service, you can engage tens of thousands of voters
							through a single experience, on a single webpage. With personal,
							one-on-one conversations, you can gather more data than ever
							before. Our customized portal is designed to reflect only the
							content that is crucial to you and your campaign.
						</StyledParagraph>
						<StyledParagraph color={MID_GRAY}>
							Are you curious about the most popular issues? Do you want to know
							where your viewers are located or where they're coming from, such
							as Instagram or Facebook? Are you interested in the number of
							click-throughs on your CTAs? This valuable data is crucial to
							inform your future marketing strategies throughout your campaign.
						</StyledParagraph>
						<div
							css={{
								display: "flex",
								justifyContent: "space-between",
								marginTop: 25,
							}}
						>
							<img
								src={geoChart}
								alt="geo-chart-example"
								css={{ width: "47.5%" }}
							/>
							<img
								src={barChart}
								alt="bar-chart-example"
								css={{ width: "47.5%", objectFit: "contain" }}
							/>
						</div>
					</div>
					<div
						id="grid-section-2"
						css={{
							display: "grid",
							gap: 20,
							gridTemplateColumns: "1fr 1fr",
							gridAutoRows: "auto",
						}}
					>
						<FeatureHighlight
							color={MID_GRAY}
							title="Know What Is Most Important to Your Voters"
						>
							<p>
								See which issues your voters are most interested in in real
								time. And gather their feedback on your positions.
							</p>
						</FeatureHighlight>
						<FeatureHighlight
							color={MID_GRAY}
							title="Gather Geolocation 
              (Including IP Address) Data"
						>
							<p>
								We partner with the industry's best geolocation data providers
								to help decipher where your voters are visiting your site from.
								You can even present custom content depending on their location.
							</p>
						</FeatureHighlight>
						<FeatureHighlight
							color={MID_GRAY}
							title="Test Out Different Approaches (A/B Testing)"
						>
							<p>
								Try sharing different variations of messages on various topics
								with your voters and see which ones connect, and which ones
								don't.
							</p>
						</FeatureHighlight>
						<img
							src={pieChart}
							alt="pie-chart-example"
							css={{ width: "100%" }}
						/>
						<div css={{ gridColumn: "span 2" }}>
							<FeatureHighlight
								color={MID_GRAY}
								title="Completely Extendable"
								additionalCSS={css({ marginTop: 0 })}
							>
								<p>
									We are happy to work with your campaign to add additional data
									gathering and reporting capabilities. If there is some
									information that you want to gather from your voters, we'll
									find a way to help you do it.
								</p>
							</FeatureHighlight>
						</div>
					</div>
				</div>
			</div>
			<img
				src={divider4}
				css={{
					maxWidth: "100%",
					position: "absolute",
					bottom: 0,
					left: 0,
					// [mediaQueries[0]]: { top: "-6%" },
					// [mediaQueries[1]]: { top: -55 },
					// [mediaQueries[2]]: { top: -5 },
					// [mediaQueries[3]]: { top: -25 },
				}}
				alt="section-divider"
			/>
		</section>
	);
}

export const VIBRANT_RED = "#D90028";
export const RED = "#D11F3D";
export const MUTED_RED = "#B73950";

export const VIBRANT_BLUE = "#0058B7";
export const BLUE = "#3473B9";
export const MUTED_BLUE = "#4D75A1";

export const MID_GRAY = "#595959";
export const DARK_GRAY = "#232323";

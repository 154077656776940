/** @jsxImportSource @emotion/react */

import { MID_GRAY } from "../colors";
import { mediaQueries } from "../mediaQueries";

import divider from "../assets/images/divider-2.png";
import SectionHeader from "./SectionHeader";
import StyledParagraph from "./StyledParagraph";
import { css } from "@emotion/react";

export default function Experience() {
  return (
    <section
      id="traditional-trailers-part-1-centering-div"
      css={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        width: "100%",
        boxSizing: "border-box",
        padding: 35,
        [mediaQueries[1]]: {
          padding: 75,
        },
      }}>
      <div
        css={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          position: "relative",
          width: "100%",
          fontFamily: "Inter",
          marginBottom: 50,
        }}>
        <iframe
          title="future-campaign-demo"
          src="https://campaign-interactive.vercel.app/"
          allow="autoplay; fullscreen"
          allowFullScreen
          css={{
            aspectRatio: "16/9",
            border: "none",
            maxHeight: "90vh",
            width: "100%",
          }}
        />
        <SectionHeader text="See for yourself" color={MID_GRAY} />
        <StyledParagraph
          color={MID_GRAY}
          additionalCSS={css({
            [mediaQueries[1]]: { maxWidth: "60%" },
          })}>
          Future Campaign offers a powerful platform to engage voters and convey
          key messages effectively. By integrating interactive features such as
          clickable elements, real-time polling, and personalized content, the
          candidate can connect with voters on a level never seen before. This
          technology creates an immersive and engaging experience, ultimately
          enhancing the candidate's ability to build connections, mobilize
          support, and achieve campaign goals.
        </StyledParagraph>
      </div>
      <img
        src={divider}
        css={{
          maxWidth: "100%",
          position: "absolute",
          bottom: -10,
          left: 0,
          zIndex: 100,
          [mediaQueries[0]]: { bottom: -15 },
          [mediaQueries[1]]: { bottom: -20 },
          [mediaQueries[2]]: { bottom: -25 },
          [mediaQueries[3]]: { bottom: -30 },
          [mediaQueries[4]]: { bottom: -50 },
        }}
        alt="section-divider"
      />
    </section>
  );
}

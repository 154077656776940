/** @jsxImportSource @emotion/react */

import { SerializedStyles, css } from "@emotion/react";
import { mediaQueries } from "../mediaQueries";
import { CSSProperties } from "react";

type SectionHeaderProps = {
  text: string;
  color: CSSProperties["color"];
  additionalCSS?: SerializedStyles;
};

export default function SectionHeader({
  text,
  color,
  additionalCSS = css(),
}: SectionHeaderProps) {
  return (
    <h3
      css={[
        {
          fontSize: 20,
          fontWeight: 800,
          color,
          marginBottom: 10,
          textTransform: "uppercase",
          [mediaQueries[0]]: {
            fontSize: 30,
            lineHeight: 1,
          },
          [mediaQueries[1]]: {},
          [mediaQueries[2]]: { fontSize: 40 },
          [mediaQueries[3]]: {},
          [mediaQueries[4]]: {
            fontSize: 60,
          },
        },
        additionalCSS,
      ]}>
      {text}
    </h3>
  );
}

/** @jsxImportSource @emotion/react */

import { SerializedStyles, css } from "@emotion/react";
import { mediaQueries } from "../mediaQueries";
import { CSSProperties, ReactNode } from "react";

type FeatureHighlightProps = {
  title: string;
  children: ReactNode;
  color: CSSProperties["color"];
  additionalCSS?: SerializedStyles;
};

export default function FeatureHighlight({
  title,
  children,
  color,
  additionalCSS = css(),
}: FeatureHighlightProps) {
  return (
    <div>
      <h4
        css={[
          {
            fontSize: 12,
            fontWeight: 700,
            color,
            marginBottom: -10,
            [mediaQueries[0]]: {
              fontSize: 14,
            },
            [mediaQueries[1]]: { fontSize: 14 },
            [mediaQueries[2]]: { fontSize: 14 },
            [mediaQueries[3]]: { fontSize: 16 },
            [mediaQueries[4]]: {},
          },
          additionalCSS,
        ]}>
        {title}
      </h4>
      <div
        css={{
          fontSize: 11,
          lineHeight: 1.3,
          fontWeight: 400,
          color,
          [mediaQueries[0]]: {
            fontSize: 12,
            lineHeight: 1.2,
          },
          [mediaQueries[1]]: { fontSize: 14 },
          [mediaQueries[2]]: { fontSize: 14 },
          [mediaQueries[3]]: { fontSize: 16 },
          [mediaQueries[4]]: {},
        }}>
        {children}
      </div>
    </div>
  );
}

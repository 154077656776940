/** @jsxImportSource @emotion/react */

import launchBackground from "../../assets/images/launch-background.jpg";

import { mediaQueries } from "../../mediaQueries";
import InterestForm from "./InterestForm";

export default function Launch() {
  return (
    <section
      id="Launch"
      css={{
        position: "relative",
        backgroundImage: `url(${launchBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: 35,
        paddingTop: 50,
        paddingBottom: 75,
      }}>
      <div
        id="Launch-text"
        css={{
          fontFamily: "Inter",
          color: "white",

          maxWidth: "100%",

          [mediaQueries[0]]: {},
          [mediaQueries[1]]: { padding: 50 },
          [mediaQueries[2]]: {},
          [mediaQueries[3]]: { paddingTop: 100, paddingBottom: 100 },
        }}>
        <h2
          css={{
            fontSize: 14,
            fontStyle: "italic",
            fontWeight: 400,
            margin: 0,
            [mediaQueries[0]]: {
              fontSize: 16,
              lineHeight: 1,
            },
            [mediaQueries[1]]: { fontSize: 16 },
            [mediaQueries[2]]: { fontSize: 18 },
            [mediaQueries[3]]: { fontSize: 20 },
            [mediaQueries[4]]: { fontSize: 22 },
          }}>
          ARE YOU READY
        </h2>
        <h2
          css={{
            fontSize: 14,
            fontStyle: "italic",
            fontWeight: 400,
            margin: 0,
            [mediaQueries[0]]: {
              fontSize: 16,
              lineHeight: 1,
            },
            [mediaQueries[1]]: { fontSize: 16 },
            [mediaQueries[2]]: { fontSize: 18 },
            [mediaQueries[3]]: { fontSize: 20 },
            [mediaQueries[4]]: { fontSize: 22 },
          }}>
          TO LAUNCH YOUR
        </h2>
        <h1
          css={{
            fontSize: 42,
            lineHeight: 0.9,
            fontStyle: "italic",
            fontWeight: 900,
            margin: 0,
            marginBottom: 35,
            //
            [mediaQueries[0]]: {
              fontSize: 46,
              marginLeft: -5,
              maxWidth: "25%",
            },
            [mediaQueries[1]]: {
              fontSize: 52,
            },
            [mediaQueries[2]]: { fontSize: 56 },
            [mediaQueries[3]]: { fontSize: 62 },
            [mediaQueries[4]]: { fontSize: 68 },
          }}>
          FUTURE CAMPAIGN?
        </h1>
        <InterestForm />
      </div>
    </section>
  );
}

/** @jsxImportSource @emotion/react */

import Analytics from "./components/Analytics";
import Experience from "./components/Experience";
import Launch from "./components/Launch";
import OneToOne from "./components/OneToOne";
import Welcome from "./components/Welcome";

function App() {
  return (
    <div
      id="future-campaign"
      css={{
        width: "100%",
      }}>
      <Welcome />
      <Experience />
      <OneToOne />
      <Analytics />
      <Launch />
    </div>
  );
}

export default App;

/** @jsxImportSource @emotion/react */

import { SerializedStyles, css } from "@emotion/react";
import { mediaQueries } from "../mediaQueries";
import { CSSProperties, ReactNode } from "react";

type StyledParagraphProps = {
  children: ReactNode;
  color: CSSProperties["color"];
  additionalCSS?: SerializedStyles;
};

export default function StyledParagraph({
  children,
  color,
  additionalCSS = css(),
}: StyledParagraphProps) {
  return (
    <h3
      css={[
        {
          fontSize: 12,
          fontWeight: 400,
          color,
          marginBottom: 10,
          [mediaQueries[0]]: {
            fontSize: 14,
          },
          [mediaQueries[1]]: { fontSize: 16 },
          [mediaQueries[2]]: { fontSize: 18 },
          [mediaQueries[3]]: { fontSize: 20 },
          [mediaQueries[4]]: {},
        },
        additionalCSS,
      ]}>
      {children}
    </h3>
  );
}

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import voterCollage from "../assets/images/voter-image-collage.jpg";

import { mediaQueries } from "../mediaQueries";
import { DARK_GRAY } from "../colors";
import SectionHeader from "./SectionHeader";
import StyledParagraph from "./StyledParagraph";
import FeatureHighlight from "./FeatureHighlight";

export default function OneToOne() {
	return (
		<section
			id="one-to-one"
			css={{
				position: "relative",
				// height: "90vh",
				backgroundColor: DARK_GRAY,
				padding: 35,
				[mediaQueries[0]]: {
					padding: 75,
					paddingTop: 125,
					paddingBottom: 125,
				},
			}}
		>
			<div
				css={{
					display: "grid",
					gridTemplateColumns: "1fr",
					gridAutoRows: "auto",
					gap: 50,
					[mediaQueries[1]]: {
						gridTemplateColumns: "1fr 1fr",
						gridTemplateRows: "1fr",
					},
				}}
			>
				<div
					id="welcome-text"
					css={{
						fontFamily: "Inter",
						color: "white",
						flex: 1,
						order: 1,
						[mediaQueries[1]]: {
							order: 0,
						},
					}}
				>
					<SectionHeader
						text="One-to-one"
						color={"white"}
						additionalCSS={css({ margin: 0 })}
					/>
					<SectionHeader
						text="voter experiences"
						color={"white"}
						additionalCSS={css({ marginTop: -5 })}
					/>
					<StyledParagraph color="white">
						The Future Campaign platform gives each voter the chance to explore
						your candidate's history, understand their positions, and discover
						meaningful ways to get involved. All of this happens from within one
						seamless interactive experience that is personal and unique to each
						constituent.
						<br />
					</StyledParagraph>

					<div>
						<FeatureHighlight
							title="Increased Participation"
							color="white"
							additionalCSS={css({
								textTransform: "uppercase",
								marginTop: "4em",
							})}
						>
							<p>
								By tailoring your experiences to address each voter's specific
								concerns, you retain their attention and amplify the
								effectiveness of your communication. In this way, you save time
								for the voter, boost their engagement, and allow each
								constituent to be more receptive to your invitations to get
								involved.
							</p>
						</FeatureHighlight>
						<FeatureHighlight
							title="Navigate Changing Tides"
							color="white"
							additionalCSS={css({
								textTransform: "uppercase",
								marginTop: "4em",
							})}
						>
							<p>
								Just like a successful campaign, the Future Campaign platform is
								built to change and adapt over time, allowing you to update your
								experience and respond to current events throughout your
								campaign in a way that remains relevant and fresh to voters.
							</p>
						</FeatureHighlight>
					</div>
				</div>
				<img
					src={voterCollage}
					css={{
						maxHeight: "50vh",
						width: "100%",
						objectFit: "contain",
						[mediaQueries[1]]: { maxHeight: "75vh" },
					}}
					alt="section-divider"
				/>
			</div>

			{/* <img
        src={divider}
        css={{
          maxWidth: "100%",
          position: "absolute",
          bottom: 0,
          left: 0,
        }}
        alt="section-divider"
      /> */}
		</section>
	);
}
